import React, { useEffect } from 'react';
import { useState } from 'react';
import { Badge, Typography, Upload, Spin, Modal, Checkbox, Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, LoadingOutlined, EyeOutlined } from '@ant-design/icons';
import RenderFiles from './RenderFiles';
import { isMobileOnly } from 'react-device-detect';
import RenderSingFile from './RenderSingFile';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReduxStore } from '../DocumentBoxesComponent';
import _ from 'lodash';
import moment from 'moment';

const { Text } = Typography;

type Props = {
	data: any;
	clickTab: any;
	index: any;
	handleClickPlus: any;
	playToBeforeUpload: any;
	visibleAfterBanksModal: any;
	setVisibleAfterBanksModal: any;
	loader: any;
	valuesData: any;
	modalDocument: any;
	noTypeFile: any;
	dataValueInfoUpload: any;
	documentsRefresh: any;
	env: any;
	images: Images;
	platform: any;
};

type Images = {
	tesoro: any;
	agencia: any;
	plus: any;
};

const RenderCards = ({
	data,
	clickTab,
	index,
	handleClickPlus,
	playToBeforeUpload,
	visibleAfterBanksModal,
	setVisibleAfterBanksModal,
	loader,
	valuesData,
	modalDocument,
	noTypeFile,
	dataValueInfoUpload,
	documentsRefresh,
	images: { tesoro, agencia, plus },
	env,
	platform
}: Props) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
	const [modalSimpleNote, setModalSimpleNote] = useState(false);
	const [modalSign, setModalSign] = useState(false);
	const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
	const [fileBank, setFileBank] = useState<any>(undefined);
	const [loading, setLoading] = useState(false);
	const [docSigned, setDocSigned] = useState<any>(undefined);
	const [nameBank, setNameBank] = useState<any>(undefined);
	const params = useParams<any>();
	let dataMortgage = useSelector<ReduxStore, any>((state) => state.data.dataSummary);
	dataMortgage = _.find(dataMortgage, (d) => d.operationId === params.operationId);
	const [countries, setContries] = useState<any>(undefined);
	const [enviroment, setEnviroment] = useState<any>(undefined);

	useEffect(() => {
		axios.get(`${env.api.url}/v1/countries`).then((response) => {
			setContries(response.data.data);
		});
		if (env.api.url === 'https://dev.api.gibobs.net') {
			setEnviroment('dev');
		} else if (env.api.url === 'https://staging.api.gibobs.net') {
			setEnviroment('staging');
		} else {
			setEnviroment('prod');
		}
	}, []);

	const renderSign = () => {
		if (nameBank === 'consent_sabadell') {
			return (
				<div style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}>
					<Checkbox onChange={onChangeDocs} value="true">
						<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0' }}>
							{t('doc.sabadellSign')}
						</Text>
					</Checkbox>
				</div>
			);
		} else {
			return (
				<div style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}>
					<RenderSingFile
						setDocSigned={setDocSigned}
						nameBank={nameBank}
						dataMortgage={dataMortgage && dataMortgage}
						clickTab={clickTab}
						form={form}
						countries={countries}
					/>
				</div>
			);
		}
	};

	const onChangeDocs = (value: any) => {
		if (docSigned !== true) {
			axios
				.post(
					`${env.api.url}/docs/documents/sign-document`,
					{
						operationId: params.operationId,
						owner: clickTab,
						consentCheck: value.target.checked,
						typeDocument: nameBank
					},
					{
						headers: { Authorization: `Bearer ${userToken}` }
					}
				)
				.then((response) => {
					setDocSigned(true);

					dataValueInfoUpload('consent');
					documentsRefresh();
				});
		}
	};

	const readerPdf = (name: string) => {
		if (name === 'consent_ibercaja') {
			setFileBank(
				`https://gibobs-${enviroment}-cdn.s3.eu-central-1.amazonaws.com/consent-documents/consent-ibercaja.pdf`
			);
			setLoading(true);
			setNameBank(name);
		}

		if (name === 'consent_caixabank') {
			setFileBank(
				`https://gibobs-${enviroment}-cdn.s3.eu-central-1.amazonaws.com/consent-documents/consent-caixa.pdf`
			);
			setLoading(true);
			setNameBank(name);
		}

		if (name === 'consent_sabadell') {
			setFileBank(
				`https://gibobs-${enviroment}-cdn.s3.eu-central-1.amazonaws.com/consent-documents/consent-sabadell.pdf`
			);
			setLoading(true);
			setNameBank(name);
		}
		if (name === 'consent_targobank') {
			setFileBank(
				`https://gibobs-${enviroment}-cdn.s3.eu-central-1.amazonaws.com/consent-documents/consent-targobank.pdf`
			);
			setLoading(true);
			setNameBank(name);
		}
		if (name === 'consent_caja_rural_sur') {
			setFileBank(
				`https://gibobs-${enviroment}-cdn.s3.eu-central-1.amazonaws.com/consent-documents/consent-caja-rural-sur.pdf`
			);
			setLoading(true);
			setNameBank(name);
		}
		if (name === 'consent_sabadell_extra') {
			setFileBank(
				`https://gibobs-${enviroment}-cdn.s3.eu-central-1.amazonaws.com/consent-documents/consent-sabadell-extra.pdf`
			);
			setLoading(true);
			setNameBank(name);
		}
		if (name === 'consent_abanca') {
			setFileBank(
				`https://gibobs-${enviroment}-cdn.s3.eu-central-1.amazonaws.com/consent-documents/consent-abanca.pdf`
			);
			setLoading(true);
			setNameBank(name);
		}
		if (name === 'consent_bankinter') {
			setFileBank(
				`https://gibobs-${enviroment}-cdn.s3.eu-central-1.amazonaws.com/consent-documents/consent-bankinter.pdf`
			);
			setLoading(true);
			setNameBank(name);
		}
	};

	const sendSign = () => {
		if (nameBank !== 'consent_caixabank' && nameBank !== 'consent_sabadell') {
			form
				.validateFields()
				.then((values) => {
					let data = [
						{
							key: clickTab,
							subkey: 'name',
							value: values.name
						},
						{
							key: clickTab,
							subkey: 'firstSurname',
							value: values.firstSurname
						},
						{
							key: clickTab,
							subkey: 'lastSurname',
							value: values.lastSurname
						},
						{
							key: clickTab,
							subkey: 'nif',
							value: values.nif
						},
						{
							key: clickTab,
							subkey: 'birthdate',
							value: moment(values?.birthdate, 'DD-MM-YYYY').format('YYYY-MM-DD')
						},
						{
							key: clickTab,
							subkey: 'email',
							value: values.email
						},
						{
							key: clickTab,
							subkey: 'phone',
							value: values.phone
						},
						{
							key: clickTab,
							subkey: 'address',
							value: values.address
						},
						{
							key: clickTab,
							subkey: 'population',
							value: values.population
						},
						{
							key: clickTab,
							subkey: 'province',
							value: values.province
						},
						{
							key: clickTab,
							subkey: 'postalCode',
							value: values.postalCode
						},

						{
							key: clickTab,
							subkey: 'cityzenship',
							value: values.cityzenship
						},
						{
							key: clickTab,
							subkey: 'birthCountry',
							value: values.birthCountry
						},
						{
							key: clickTab,
							subkey: 'taxResidency',
							value: values.taxResidency
						},
						{
							key: clickTab,
							subkey: 'nationality',
							value: values.nationality
						},
						{
							key: clickTab,
							subkey: 'maritalStatus',
							value: values.maritalStatus
						}
					];
					data = data.filter((item) => item.value !== undefined);
					axios
						.post(
							`${env.api.url}/v1/mortgages/data/multiple/update/${params.operationId}`,
							{ values: data },
							{ headers: { Authorization: `Bearer ${userToken}` } }
						)
						.then((response) => {
							if (response.data.success) {
								form.resetFields();
								signFile();
							} else {
								message.error(t('m_common.requestErrorTitle'));
							}
						});
				})
				.catch((errors) => {
					console.error('Errores de validación:', errors);
				});
		} else {
			if (nameBank !== 'consent_sabadell') {
				signFile();
			}
			if (nameBank === 'consent_sabadell') {
				setModalSign(false);
				setFileBank(undefined);

				dataValueInfoUpload();
				documentsRefresh();
			}
		}
	};

	const signFile = () => {
		setModalSign(false);
		setFileBank(undefined);
		axios
			.post(
				`${env.api.url}/docs/documents/sign-document`,
				{
					operationId: params.operationId,
					owner: clickTab,
					sign: docSigned,
					typeDocument: nameBank
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				dataValueInfoUpload();
				documentsRefresh();
			});
	};

	const renderImage = (data: any) => {
		if (data?.name === 'consent_caixabank') {
			return 'caixabank_iso.png';
		} else if (data?.name === 'consent_ibercaja') {
			return 'ibercaja_iso.png';
		} else if (data?.name === 'consent_targobank') {
			return 'targobank_iso.png';
		} else if (data?.name === 'consent_sabadell') {
			return 'sabadell_iso.png';
		} else if (data?.name === 'consent_caja_rural_sur') {
			return 'cajaruralsur_iso.png';
		} else if (data?.name === 'consent_bankinter') {
			return 'bankinter_iso.png';
		} else {
			return 'cajaruralsur_iso.png';
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%'
			}}>
			<div
				className="wrapper-flex"
				style={{
					alignItems: 'flex-start'
				}}>
				{noTypeFile === undefined && (
					<div>
						<div
							className="wrapper-icons-render-cards"
							style={{
								marginTop: loader && -4
							}}>
							{!loader &&
								(data?.completed[clickTab] === false ? (
									<Badge
										dot={true}
										size="small"
										className="gb-badget"
										style={{ marginTop: 10 }}></Badge>
								) : (
									<CheckOutlined className="gb-check" style={{ fontSize: 15 }} />
								))}
							{loader && <Spin indicator={antIcon} className="spin" />}
						</div>
					</div>
				)}
				{noTypeFile !== undefined && (
					<div>
						<div
							className="wrapper-icons-render-cards"
							style={{
								marginTop: loader && -4
							}}>
							{!loader &&
								(noTypeFile === undefined ? (
									<Badge dot={true} size="small" color="#FF4D4F"></Badge>
								) : (
									<CheckOutlined style={{ color: '#52C41A', fontSize: 10 }} />
								))}
							{loader && <Spin indicator={antIcon} className="spin" />}
						</div>
					</div>
				)}
				<div>
					{data?.name === 'docs.othersDocs' ? (
						<Text className="render-card-name">{t(`${data?.name}`, data?.title)}</Text>
					) : (
						<Text className="render-card-name">
							{platform === 'gibobs'
								? data.name === 'bankreader'
									? t('doc.differentStages3Automatic')
									: data.name === 'nif'
									? t('form.dni')
									: data.name === 'laboralLife'
									? t('newDoc.laboralLife')
									: data.name === 'payroll'
									? t('newDoc.payroll')
									: t(`documentTypes.${data?.name}`, data?.title)
								: t(`documentTypes.${data?.name}`, data?.title)}
						</Text>
					)}
					{data?.name === 'bankreader' && <Text className="render-card-name">*</Text>}
				</div>
			</div>

			<div style={{ marginBottom: 50, marginLeft: 16, marginTop: 16 }}>
				<RenderFiles
					clickTab={clickTab}
					file={data}
					valuesData={valuesData}
					modalDocument={modalDocument}
					env={env}
				/>
			</div>

			{`documentTypes.${data?.name}` !== 'documentTypes.bankreader' &&
				`documentTypes.${data?.name}` !== 'documentTypes.simpleNote' &&
				!data.name.includes('consent') && (
					<div
						className="wrapper-flex"
						style={{
							justifyContent: 'space-between',
							marginLeft: 16,
							position: 'absolute',
							bottom: 16,
							width: '85%'
						}}>
						<Upload multiple={true} beforeUpload={(file) => playToBeforeUpload(file, data, index)}>
							<div onClick={() => handleClickPlus(data?.name)} className="wrapper-flex">
								<div
									style={{
										height: 16,
										width: 16,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										marginRight: 4
									}}>
									<img
										src={plus}
										alt="plus"
										height={11}
										style={{
											height: platform === 'sabadell' ? 16 : 24,
											width: platform === 'sabadell' ? 16 : 24
										}}
									/>
								</div>
								<Text className="attach-document-text">{t('file.newOnboardingDocs')}</Text>
							</div>
						</Upload>
						<div>
							{(data?.name === 'laboralLife' || data?.name === 'contributionBase') && (
								<img src={tesoro} alt="tesoro" style={{ height: 16, width: 16 }}></img>
							)}
							{(data?.name === 'irpf2' ||
								data?.name === 'irpf' ||
								data?.name === 'statement303' ||
								data?.name === 'statement130') && (
								<img src={agencia} alt="agencia" style={{ height: 16, width: 16 }}></img>
							)}
						</div>
					</div>
				)}
			{(`documentTypes.${data?.name}` === 'documentTypes.bankreader' ||
				`documentTypes.${data?.name}` === 'documentTypes.simpleNote') &&
				!data.name.includes('consent') && (
					<div
						style={{
							marginLeft: 16,
							display: 'flex',
							flexWrap: 'wrap',
							position: 'absolute',
							bottom: 16,
							width: '85%'
						}}>
						<Text
							onClick={() =>
								`documentTypes.${data?.name}` === 'documentTypes.simpleNote'
									? setModalSimpleNote(true)
									: setVisibleAfterBanksModal(!visibleAfterBanksModal)
							}
							className="attach-document-text">
							{t('doc.newBankflipButton')}&nbsp;
						</Text>

						<div className="wrapper-flex">
							<div className="render-card-name" style={{ marginRight: 4 }}>
								o
							</div>

							<Upload multiple={true} beforeUpload={(file) => playToBeforeUpload(file, data)}>
								<div style={{ border: 'none' }} onClick={() => handleClickPlus(data?.name)}>
									<Text className="attach-document-text">{t('file.newOnboardingDocs')}</Text>
								</div>
							</Upload>
						</div>
					</div>
				)}
			{`documentTypes.${data?.name}` !== 'documentTypes.bankreader' &&
				`documentTypes.${data?.name}` !== 'documentTypes.simpleNote' &&
				data.name.includes('consent') && (
					<div
						className="wrapper-flex"
						style={{
							justifyContent: 'space-between',
							marginLeft: 16,
							position: 'absolute',
							bottom: 16,
							width: '85%'
						}}>
						{data.completed[clickTab] === false && (
							<div
								onClick={() => {
									setModalSign(!modalSign);
									readerPdf(data.name);
								}}>
								<div className="wrapper-flex">
									<div
										style={{
											height: 16,
											width: 16,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											marginRight: 4
										}}>
										<EyeOutlined
											style={{ color: platform === 'sabadell' ? '#006DFF' : '#02C3CD' }}
										/>
									</div>
									<Text className="attach-document-text">{t('docs.readAndSing')}</Text>
								</div>
							</div>
						)}
						{data.completed[clickTab] === false && (
							<div>
								{(data?.name === 'consent_caixabank' ||
									data?.name === 'consent_ibercaja' ||
									data?.name === 'consent_targobank' ||
									data?.name === 'consent_sabadell' ||
									data?.name === 'consent_caja_rural_sur' ||
									data?.name === 'consent_bankinter') && (
									<img
										src={
											'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
											`${renderImage(data)}`
										}
										alt="banco"
										height={20}
									/>
								)}
							</div>
						)}
					</div>
				)}

			<Modal
				className="ModalDesktopCert"
				title={t('doc.modalSimpleNoteTitle')}
				open={modalSimpleNote}
				onCancel={() => setModalSimpleNote(false)}
				onOk={() => setModalSimpleNote(false)}
				cancelButtonProps={{
					style: { display: 'none' }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '100%', marginLeft: 0 }
				}}
				okText={
					<a href="https://www.tinsa.es/store/nota-simple/" target="_blank">
						{t('doc.modalSimpleNoteButton')}
					</a>
				}
				destroyOnClose={true}
				closable={true}
				centered
				width={470}>
				<div
					style={{ color: '#748EA0', fontSize: 14, fontWeight: 400, marginTop: 24 }}
					dangerouslySetInnerHTML={{
						__html:
							platform === 'sabadell'
								? t('doc.modalSimpleNoteSabadell')
								: t('doc.modalSimpleNoteDescription')
					}}></div>
				<div
					style={{
						color: '#748EA0',
						fontSize: 10,
						fontWeight: 400,
						marginTop: 12,
						marginBottom: 10
					}}
					dangerouslySetInnerHTML={{
						__html: t('doc.modalSimpleNoteExplination')
					}}></div>
			</Modal>

			<Modal
				className="ModalDesktopCert"
				title={t('doc.sign')}
				open={modalSign}
				onCancel={() => {
					setModalSign(false);
					setFileBank(undefined);
					form.resetFields();
				}}
				onOk={() => sendSign()}
				cancelButtonProps={{
					style: { display: 'none' }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					disabled: docSigned !== undefined ? false : true,
					style: { width: '100%', marginLeft: 0 }
				}}
				okText={t('offers.accept')}
				forceRender={true}
				destroyOnClose={true}
				closable={true}
				centered
				width={isMobileOnly ? '100%' : '50%'}>
				{loading && fileBank ? (
					<div>
						<iframe src={fileBank} style={{ height: 500, width: '100%', border: 'none' }}></iframe>
					</div>
				) : (
					<Spin indicator={antIcon} className="spin" />
				)}

				{modalSign && renderSign()}
			</Modal>
		</div>
	);
};

export default RenderCards;
